var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"text-primary"},[_vm._v("Gestione del Personale ")]),_c('b-breadcrumb',{staticClass:"ml-2"},[_c('b-breadcrumb-item',[_c('feather-icon',{attrs:{"icon":"HomeIcon","scale":"1.25","shift-v":"1.25"}})],1),_c('b-breadcrumb-item',{on:{"click":function($event){return _vm.$router.push('/account/logistics/list')}}},[_vm._v(" Account Responsabili Logistica")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(" Crea Account Responsabile Logistica")])],1)],1)]},proxy:true}])},[_c('div',{staticClass:"mt-2"},[_c('h2',{staticClass:"text-primary-light"},[_vm._v("Crea Account Responsabile Logistica")]),_c('validation-observer',{ref:"validationData"},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.account.nome),callback:function ($$v) {_vm.$set(_vm.account, "nome", $$v)},expression:"account.nome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Cognome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cognome"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.account.cognome),callback:function ($$v) {_vm.$set(_vm.account, "cognome", $$v)},expression:"account.cognome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Telefono","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Telefono"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.account.telefono_interno),callback:function ($$v) {_vm.$set(_vm.account, "telefono_interno", $$v)},expression:"account.telefono_interno"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Ubicazione"}},[_c('v-select',{attrs:{"options":_vm.locations,"label":"nome_ubicazione","reduce":function (location) { return location.nome_ubicazione; }},model:{value:(_vm.account.ubicazione),callback:function ($$v) {_vm.$set(_vm.account, "ubicazione", $$v)},expression:"account.ubicazione"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Azienda","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Azienda"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.account.azienda),callback:function ($$v) {_vm.$set(_vm.account, "azienda", $$v)},expression:"account.azienda"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Ruolo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Ruolo"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.account.ruolo),callback:function ($$v) {_vm.$set(_vm.account, "ruolo", $$v)},expression:"account.ruolo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"E-mail","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"E-mail"}},[_c('b-form-input',{attrs:{"type":"email","state":errors.length > 0 ? false : null},model:{value:(_vm.account.username),callback:function ($$v) {_vm.$set(_vm.account, "username", $$v)},expression:"account.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Password"}},[_c('b-form-input',{attrs:{"type":"password","state":errors.length > 0 ? false : null},model:{value:(_vm.account.password),callback:function ($$v) {_vm.$set(_vm.account, "password", $$v)},expression:"account.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validationData}},[_vm._v(" Crea Account ")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }